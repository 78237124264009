import React from "react";
import useTitle from "../../Hooks/useTitle";





const About = () => {
  useTitle('About')
  return (
    <div className="">
      <div className="">
        <div className="lg:min-h-screen ">
          <div className="text-center ">
            <div className="flex flex-col items-center justify-center">
              <div  className="pt-20 mt-20 ">

              <h1 className="mx-10 my-6 text-4xl font-bold lg:text-6xl">Digitalize Visions into Reality</h1>
              {/* <button className="my-4 btn btn-primary">Get Started</button> */}
              </div>
              {/* <p className="py-6">
                Provident cupiditate voluptatem et in. Quaerat fugiat ut
                assumenda excepturi exercitationem quasi. In deleniti eaque aut
                repudiandae et a id nisi.
              </p> */}
              <img src="/about-main.png" className="w-7/12 pt-12 pb-10 rounded-lg " alt="about"/>
            </div>
          </div>
        </div>

        <div className="pb-5 bg-white hero lg:pb-16 lg:pr-10 lg:pl-10 lg:p-0">
          <div className="flex-col gap-16 pt-5 mt-5 hero-content lg:flex-row-reverse">
            <div className="w=2/3 lg:w-1/3 flex-row">
              <h1 className="py-4 text-4xl font-bold lg:text-5xl lg:text-left pt-14 ">
                Who We Are
              </h1>
              <div className="mx-8 lg:mx-0">

              <p className="py-2 leading-7 lg:text-left lg:flex ">
              Stacksages is a web software development company specializing in creating feature-rich,  dynamic software solutions that optimize business problems. </p>
              <p className="py-2 leading-7 lg:text-left lg:flex "> With a flexible approach, we work with any tech stack to cater to the unique requirements and preferences of our clients, ensuring a customized and satisfying experience. </p>
              </div>
              
              {/* <button className="items-center mt-4 btn btn-primary lg:flex">
                Contact Us
              </button> */}
            </div>
            <img src="/about-2.jpg" className="mx-2 rounded-lg lg:w-1/2 " alt="about"/>
          </div>
        </div>

        <div className="pb-5 bg-white hero lg:pb-16 lg:pr-10 lg:pl-10 lg:p-0">
          <div className="flex-col gap-16 pt-5 mt-5 hero-content lg:flex-row">
            <div className="w=2/3 lg:w-1/3 flex-row">
              <h1 className="py-4 text-4xl font-bold lg:text-5xl lg:text-left pt-14 ">
                Who We Are
              </h1>
              <div className="mx-8 lg:mx-0">

              <p className="py-2 leading-7 lg:text-left lg:flex ">
              Stacksages is a web software development company specializing in creating feature-rich,  dynamic software solutions that optimize business problems. </p>
              <p className="py-2 leading-7 lg:text-left lg:flex "> With a flexible approach, we work with any tech stack to cater to the unique requirements and preferences of our clients, ensuring a customized and satisfying experience. </p>
              </div>
              
              {/* <button className="items-center mt-4 btn btn-primary lg:flex">
                Contact Us
              </button> */}
            </div>
            <img src="/our-ambition-2.jpg" className="mx-2 rounded-lg lg:w-1/2 " alt="about"/>
          </div>
        </div>
      </div>
      {/* <h1 className="p-8 pt-5 pb-10 text-4xl font-semibold lg:text-5xl">
        Amazing Team
      </h1>

      <div className="grid w-3/5 grid-cols-1 gap-5 mx-auto bg-white lg:grid lg:grid-cols-3">
        {arr.map((arra, index) => (
          <div key={index} className="shadow-xl card bg-base-100">
            <figure>
              <img src="/avatar.png" className="" alt="Movie" />
            </figure>
            <div className="card-body">
              <h2 className="card-title">New movie is released!</h2>
              <p>Click the button to watch on Jetflix app.</p>
              <div className="justify-end card-actions"></div>
            </div>
          </div>
        ))}
      </div> */}

      {/* <section>
        <div className="pb-5 bg-white lg:pb-10 lg:pr-10 lg:pl-10 lg:p-0">
          <div className="flex-col gap-10 pt-5 hero-content lg:flex-row">
            <div className="w=2/3 lg:w-1/3 flex-row">
              <h1 className="py-5 text-4xl font-bold lg:text-5xl lg:text-left">
                Our Ambition
              </h1>
              <div className="mx-8 lg:mx-0">
                
              <p className="py-2 leading-7 lg:text-left lg:flex "> Stacksages is a web software development company specializing in creating feature-rich,  dynamic software solutions that optimize business problems. </p>
              <p className="py-2 leading-7 lg:text-left lg:flex "> With a flexible approach, we work with any tech stack to cater to the unique requirements and preferences of our clients, ensuring a customized and satisfying experience. </p>
              </div>

            </div>
            <img src="/our-ambition-2.jpg" className="rounded-lg lg:w-1/2" alt="" />
          </div>
        </div>
      </section> */}
    </div>
  );
};

export default About;
