import React, { useRef, useState } from "react";
import { Link, NavLink, Outlet } from "react-router-dom";

const Header = () => {
  const hide = useRef();
  const hideMenu = useRef();
  const [mobileNav, setMobileNav] = useState(true);

  function hideDropdown() {
    hide.current.classList.add("hidden");
    setTimeout(() => {
      hide.current.classList.remove("hidden");
      setMobileNav(!mobileNav);
    }, 250);
  }

  function mobileNavToggle() {
    setMobileNav(!mobileNav);

    if (mobileNav === false) {
      hide.current.classList.add("hidden");
    } else {
      hide.current.classList.remove("hidden");
    }
  }
  return (
    <div>
      <div>
        <div className="">
          <div className="absolute top-0 z-10 navbar">
            <div className="pl-5 navbar-start">
              <Link to="/" className="pl-3 text-xl font-bold normal-case ">
                <img src="/sage-4.png" className="w-36 h-18" alt="logo"></img>
              </Link>
            </div>

            <div className="navbar-end">
              <ul className="items-center hidden px-1 pr-5 space-x-10 lg:flex">
                <li>
                  <NavLink
                    className={({ isActive }) =>
                      isActive
                        ? "pb-0.5 w-1/2 border-b-2 border-primary font-semibold  "
                        : "hover:pb-0.5  hover:border-b-2 hover:border-primary  font-semibold "
                    }
                    to="/about"
                  >
                    About
                  </NavLink>
                </li>
                <li>
                  <NavLink
                    className={({ isActive }) =>
                      isActive
                        ? "pb-0.5 w-1/2 border-b-2 border-primary font-semibold  "
                        : "hover:pb-0.5  hover:border-b-2 hover:border-primary  font-semibold "
                    }
                    to="/portfolio"
                  >
                    Portfolio
                  </NavLink>
                </li>
                {/* <li tabIndex={0}>
                                    <a>
                                        Service
                                        <svg className="fill-current" xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24"><path d="M7.41,8.58L12,13.17L16.59,8.58L18,10L12,16L6,10L7.41,8.58Z" /></svg>
                                    </a>
                                    <ul className="z-10 p-2 bg-base-200">
                                        <li><a>Web Design</a></li>
                                        <li><a className=''>Web Developmnet</a></li>
                                    </ul>

                                </li> */}
                <li>
                  <NavLink
                    className={({ isActive }) =>
                      isActive
                        ? "pb-0.5 w-1/2 border-b-2 border-primary font-semibold "
                        : "hover:pb-0.5  hover:border-b-2 hover:border-primary  font-semibold "
                    }
                    to="/career"
                  >
                    Career
                  </NavLink>
                </li>
                <li>
                  <NavLink
                    className={({ isActive }) =>
                      isActive
                        ? "pb-0.5 w-1/2 border-b-2 border-primary font-semibold "
                        : "hover:pb-0.5  hover:border-b-2 hover:border-primary  font-semibold "
                    }
                    to="/blogs"
                  >
                    Blog
                  </NavLink>
                </li>
                <li>
                  <NavLink
                    className={({ isActive }) =>
                      isActive
                        ? "border border-primary px-6 py-3 rounded-lg text-primary font-semibold hover:bg-white hover:text-black  "
                        : "border  font-semibold  border-primary px-6 py-3 rounded-lg hover:bg-primary hover:text-white"
                    }
                    to="/contact"
                  >
                    Contact
                  </NavLink>
                </li>
              </ul>
              <div className="dropdown">
                <label
                  ref={hide}
                  onClick={mobileNavToggle}
                  tabIndex={0}
                  className="btn btn-ghost lg:hidden"
                >
                  {mobileNav ? (
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      className="h-7 w-7"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="2"
                        d="M2,4A1,1,0,0,1,3,3H21a1,1,0,0,1,0,2H3A1,1,0,0,1,2,4Zm1,9H21a1,1,0,0,0,0-2H3a1,1,0,0,0,0,2Zm0,8H21a1,1,0,0,0,0-2H3a1,1,0,0,0,0,2Z"
                      />
                    </svg>
                  ) : (
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      className="h-7 w-7"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="2"
                        d="M5.46967 5.46967C5.76256 5.17678 6.23744 5.17678 6.53033 5.46967L18.5303 17.4697C18.8232 17.7626 18.8232 18.2374 18.5303 18.5303C18.2374 18.8232 17.7626 18.8232 17.4697 18.5303L5.46967 6.53033C5.17678 6.23744 5.17678 5.76256 5.46967 5.46967Z"
                        fill="#000000"
                      ></path>{" "}
                      <path
                        fill-rule="evenodd"
                        clip-rule="evenodd"
                        d="M18.5303 5.46967C18.8232 5.76256 18.8232 6.23744 18.5303 6.53033L6.53035 18.5303C6.23745 18.8232 5.76258 18.8232 5.46969 18.5303C5.17679 18.2374 5.17679 17.7626 5.46968 17.4697L17.4697 5.46967C17.7626 5.17678 18.2374 5.17678 18.5303 5.46967Z"
                      />
                    </svg>
                  )}
                </label>
                <ul
                  ref={hide}
                  onClick={() => hideDropdown()}
                  tabIndex={0}
                  className="absolute flex flex-col items-center justify-center w-40 p-5 mx-auto mt-3 bg-gray-200 shadow-xl menu menu-compact dropdown-content right-2 rounded-box "
                >
                  <li>
                    <NavLink
                      className={({ isActive }) =>
                        isActive
                          ? "text-white bg-primary font-semibold  "
                          : " text-black"
                      }
                      to="/about"
                    >
                      About
                    </NavLink>
                  </li>
                  <li>
                    <NavLink
                      className={({ isActive }) =>
                        isActive
                          ? "text-white bg-primary font-semibold  "
                          : " text-black"
                      }
                      to="/portfolio"
                    >
                      Portfolio
                    </NavLink>
                  </li>
                  <li>
                    <NavLink
                      className={({ isActive }) =>
                        isActive
                          ? "text-white bg-primary font-semibold text-center "
                          : " text-black text-center"
                      }
                      to="/career"
                    >
                      Career
                    </NavLink>
                  </li>
                  <li>
                    <NavLink
                      className={({ isActive }) =>
                        isActive
                          ? "text-white bg-primary font-semibold  "
                          : " text-black"
                      }
                      to="/blogs"
                    >
                      Blog
                    </NavLink>
                  </li>
                  <li>
                    <NavLink
                      className={({ isActive }) =>
                        isActive
                          ? "text-white bg-primary font-semibold  "
                          : " text-black"
                      }
                      to="/contact"
                    >
                      Contact
                    </NavLink>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Header;
