import logo from './logo.svg';
import './App.css';
import { createBrowserRouter, RouterProvider } from 'react-router-dom';
import Main from './Components/Main/Main';
import Error from './Components/Error/Error';
import Home from './Pages/Home/Home';
import About from './Pages/About/About';
import Portfolio from './Pages/Portfolio/Portfolio';
import Blog from './Pages/Blog/Blog';
import BlogDetails from './Pages/BlogDetails/BlogDetails';
import Contact from './Pages/Contact/Contact';
import ProjectDetails from './Pages/ProjectDetails/ProjectDetails';
import axios from 'axios';
import Career from './Pages/Career/Career';
import CareerDetails from './Pages/CarreerDetails/CareerDetails';
import { useEffect } from 'react';


function App() {
  axios.defaults.baseURL = `https://server.stacksages.com/`;
  axios.defaults.headers.post['Content-Type'] = 'application/json';
  axios.defaults.headers.post['Accept'] = 'application/json';
  axios.defaults.withCredentials = false;


  const router = createBrowserRouter(
    [
      {
        path: "/",
        element: <Main></Main>,
        // errorElement:<Error></Error>,
        children : [
          {
            path: "/", 
            element: <Home></Home>,
            errorElement: <Error></Error>
          },
          {
            path: "/about", 
            element: <About></About>,
            errorElement: <Error></Error>
          },
          {
            path: "/portfolio", 
            element: <Portfolio></Portfolio> ,
           
            errorElement: <Error></Error>
          },
          {
            path: "/project-details/:slug", 
            loader:  ({params} )=> {
              return axios.get(`api/project/${params.slug}`).then( res => res.data).catch(err => {
                <Error></Error>
            })
            },
            element: <ProjectDetails></ProjectDetails> ,
            errorElement: <Error></Error>
          },
          {
            path: "/blogs", 
            element: <Blog></Blog> ,
            errorElement: <Error></Error>
          },
          {
            path: "blog-details/:id", 
            loader:  ({params} )=> {
              return axios.get(`api/blog/${params.id}`).then( res => res.data).catch(err => {
                <Error></Error>
            })},
            element: <BlogDetails></BlogDetails> ,
            errorElement: <Error></Error>
          },
          {
            path: "/contact", 
            element: <Contact></Contact> ,
            errorElement: <Error></Error>
          },
          {
            path: "/career", 
            element: <Career></Career> ,
            errorElement: <Error></Error>
          },
          {
            path: "/career/details", 
            element: <CareerDetails/> ,
            errorElement: <Error></Error>
          },
          {
            path: "*",
            element: <Error></Error>
          }
        ]
      }
    ]
  )
  return (
    
    <div className="font-mono App ">
      <RouterProvider router={router}
      ></RouterProvider>
    </div>
  );
}

export default App;
