import api from "./apiSlice";


const blogApi = api.injectEndpoints({
  endpoints: (builder) => ({
    getAllBlogs: builder.query({
      query: ({  page }) => ({
        url: `blogs?per_page=6&page=${page}`,
      }),
      providesTags: ["blogs"],
    }),
  }),
});

export const {  useGetAllBlogsQuery } = blogApi;