import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Collaboration from "../../Components/Collaboration/Collaboration";
import axios from "axios";
import { toSnakeCase } from "../../helper";
import { projectsApi } from "../../config";
import Footer from "../../Components/Footer/Footer";
import FooterAlt from "../../Components/FooterAlt/FooterAlt";
import ProjectCard from "../../Components/ProjectCard/ProjectCard";
import useTitle from "../../Hooks/useTitle";
const Portfolio = () => {
  useTitle('Portfolio')
  const [projects, setProjects] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  const [data, setData] = useState([]);
  const [page, setPage] = useState(1);

  console.log('projects:', projects);

  const fetchData = async () => {
    setIsLoading(true);
    setError(null);

    try {
      // axios.get(projectsApi + `?per_page=5&page=${page}`).then((response) => {
      //   // setPage((prevPage) => prevPage + 1);
      //   setProjects((prevItems) => [...prevItems, ...response.data.data]);
      // });
      // console.log(page);

      // const response = await fetch(`https://api.example.com/items?page=${page}`);
      // const response = await fetch(`http://127.0.0.1:8000/api/projects?per_page=${page}`);
      const response = await fetch(projectsApi + `?per_page=2&page=${page}`);
      // const response = await fetch(`https://jsonplaceholder.typicode.com/comments?_page=${page}`);
      const data = await response.json();
      setData(data);
      setProjects((prevItems) => [...prevItems, ...data.data]);
      setPage((prevPage) => prevPage + 1);
      setIsLoading(false);
    } catch (error) {
      setError(error);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  const handleScroll = () => {
    if (
      window.innerHeight + document.documentElement.scrollTop !==
        document.documentElement.offsetHeight ||
      isLoading
    ) {
      return;
    }
   if( data?.meta?.to) {
     fetchData();

   }
    

    // if(window.innerHeight + document.documentElement.scrollTop + 1 >= document.documentElement.scrollHeight){
    //   fetchData();
    // }
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, [isLoading]);

  // useEffect(() => {
  //   axios.get(projectsApi).then((response) => {
  //     setProjects(response.data.data);
  //   });
  // }, []);

  // console.log(projects);

  return (
    <div className="">
      <section className="">
        <h1 className="px-20 pt-32 pb-3 text-4xl font-semibold pl-14 lg:text-6xl">
          Our Accomplishment
        </h1>

        <ProjectCard projects={projects} ></ProjectCard>
      </section>
      {data?.meta?.to === null ? (
        <div>
          <Collaboration></Collaboration>
          <FooterAlt></FooterAlt>
        </div>
      ) : (
        <div className="w-3/4 p-10 mx-auto mb-10 border-2 border-gray-300 border-solid mt-7 rounded-xl animate-pulse lg:w-3/4">
          <p className="h-2 bg-gray-300 rounded-lg w-52 dark:bg-gray-600"></p>

          <p className="w-48 h-2 mt-6 bg-gray-200 rounded-lg dark:bg-gray-700"></p>
          <p className="w-full h-2 mt-4 bg-gray-200 rounded-lg dark:bg-gray-700"></p>
          <p className="w-64 h-2 mt-4 bg-gray-200 rounded-lg dark:bg-gray-700"></p>
          <p className="w-4/5 h-2 mt-4 bg-gray-200 rounded-lg dark:bg-gray-700"></p>
        </div>
      )}
      {/* <Collaboration></Collaboration> */}
    </div>
  );
};

export default Portfolio;
