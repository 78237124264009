import React, { useEffect } from "react";
import { useLoaderData } from "react-router-dom";
import Pill from "../../Components/Pill/Pill";


const ProjectDetails = () => {


  const project = useLoaderData();

  const projectData = project.data;
  // console.log(projectData);

  return (
    <div className="pt-14">
      <div className="mt-20">
        <Pill pillData={projectData?.technologies}></Pill>
        <h1 className="pt-5 pb-1 w-3/5 mx-auto font-bold text-3xl lg:text-5xl">
          {projectData.title}
        </h1>

        {/* img */}
        <div>
          <img
            src={
              projectData.image === null
                ? "./../default-project-img.png"
                : projectData.image
            }
            className="w-4/5 px-0 lg:px-10 my-5 lg:my-10 lg:w-4/5 mx-auto h-1/5 bg-gray-100 rounded-lg shadow-xl "
            alt="blog"
          />
        </div>
        <div className="w-11/12 lg:w-11/12 justify-around items-center gap-2 flex flex-col lg:flex lg:flex-row mx-auto p-5 lg:p-5">
          <div className="flex items-center gap-2">
            <h1 className="font-semibold">Client</h1>
            <h1 className="">{projectData.client}</h1>
          </div>
          <div className="flex items-center">
            <h1 className="font-bold"> Date:</h1>
            <div className="pl-1">{projectData.date.month_year}</div>
          </div>
          <div className="flex items-center">
            <div className="">
              <h1 className="font-bold">Category: </h1>
            </div>
            <div className="pl-1">{projectData.category}</div>
          </div>
        </div>
        <div className="w-3/4 lg:w-2/4 pt-5 pb-10 mx-auto text-start">
          <div className=" text-lg leading-9">
            <p
              dangerouslySetInnerHTML={{
                __html: projectData.description,
              }}
            >
              {}
            </p>
          </div>
          <div className=" text-lg leading-9"></div>
        </div>
      </div>
    </div>
  );
};

export default ProjectDetails;
