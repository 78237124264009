import React from 'react';

const Button = ({text , img}) => {
    return (
        <div className=' w-max flex  items-center gap-5 hover:bg-white hover:text-black border-primary border border-solid bg-primary px-6 font-semibold text-xl py-3 text-white rounded-lg shadow-xl'>
            {text} {img}
            
        </div>
    );
};

export default Button;