import React from 'react';
import { Link } from 'react-router-dom';
import { toSnakeCase } from "../../helper";
import parse from 'html-react-parser';

const ProjectCard = ( { projects }) => {
    return (
        <div className="pb-5">
          {" "}
          {projects.map((project, index) => (
            <div
              key={index}
              className="w-3/4 mx-auto my-10 border border-white border-solid shadow-xl rounded-xl md:w-3/4 bg-zinc-50 hover:border hover:border-primary lg:my-20"
            >
              <Link
                to={`/project-details/${project.slug}`}
                className="flex flex-col md:flex-row md:gap-20 "
              >
                <div className="md:w-11/12">
                  
                    <img
                      className="lg:11/12 xl:w-11/12 bg-blue-50 rounded-tl-xl rounded-tr-xl lg:rounded-tr-none lg:rounded-l-xl"
                      // src="./default-project-img.png"
                      src={
                        project.image === null
                          ? "./default-project-img.png"
                          : project.image
                        // project.image
                      }
                      alt="Shoes"
                    />
                  
                </div>
                <div className="flex flex-col justify-around pt-5 mt-5 md:pr-20 lg:pr-28">
                  <div className="">
                    <h2 className="text-2xl font-semibold capitalize md:text-2xl lg:text-3xl">
                      {project.title}
                    </h2>
                    <div  className="pt-5 pb-3 text-justify md:hidden lg:block lg:text-sm xl:text-base px-9 lg:px-0">
                      {  project.description.length > 150
                        ? parse( project.description.slice(0, 150) + "..." )
                        : project.description}{" "}
                    </div>
                  </div>
                  <div className="flex justify-center gap-4 p-1 pb-8 pt-7 lg:pt-0 lg:p-3 lg:gap-4">
                    {project.technologies.map((item, index) => (
                      <figure key={index}>
                        <img
                          src={`icons/${toSnakeCase(item.name)}.png`}
                          className="w-6 h-6 lg:w-8 lg:h-8"
                          srcSet=""
                          alt=""
                        />
                      </figure>
                    ))}
                  </div>
                </div>
              </Link>
            </div>
          ))}
        </div>
    );
};

export default ProjectCard;