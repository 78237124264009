import React from "react";

const Pill = ({ pillData }) => {
  return (
    <div className="flex gap-5 justify-center">
      {pillData?.map((projectTech, index) => (
        <div key={index} className=" ">
          <div className="px-7 mx-auto  py-0.5  bg-primary rounded-2xl text-white font-semibold uppercase">
            {projectTech.name}
          </div>
        </div>
      ))}
    </div>
  );
};

export default Pill;
