import React from "react";
import Button from "../Button/Button";
import { Link } from "react-router-dom";

const componentName = () => {
  return (
    <div>
      <div className="hero pb-5 lg:pb-10 lg:pr-10 lg:pl-10 lg:p-0 min-h-screen bg-white">
        <div className="hero-content flex-col mt-5 pt-20 gap-16 lg:flex-row">
          <div className="w=2/3 lg:w-1/3 flex-row">
            <h1 className="py-4 text-4xl  font-bold lg:text-6xl lg:text-left pt-14 ">
              Bad News...
            </h1>
            <div className="mx-8 lg:mx-0">
              <p className="py-5  lg:text-left font-semibold text-xl  lg:flex leading-7 ">
                Sorry, We Can't find the page you are looking for.{" "}
              </p>
            </div>
    <div className="my-5 flex justify-center lg:justify-start">
        
            <Link to="/">
              <Button className="" text="Go Home"></Button>
            </Link>
    </div>
          </div>
          <img
            src="/error.webp"
            className="mx-2 lg:w-1/2 rounded-lg "
            alt="about"
          />
        </div>
      </div>
    </div>
  );
};

export default componentName;
