import React from "react";

const CareerDetails = () => {
  return (
    <div className="">
      <h1 className="pt-20"></h1>
      <div className="container  relative mx-auto">
        <div className="grid grid-cols-1 lg:grid-cols-4">
          <div className="col-span-3 pt-10 pl-10 ">
            <p className=" text-3xl md:text-5xl font-bold  text-left ">
              Assistant Operations Executive (Intern)
            </p>

            <p className="py-10 text-left text-xl ">
              We are looking for an Assistant Operations Executive - Intern
              (Paid) followed by a contractual position for the Business
              Development Team. The ideal candidate will work with the team to
              do market research and gather information on prospective clients
              through a customized process.
            </p>

            <div className="mb-8">
              <p className="text-2xl font-bold  text-left py-3   ">
                Key Responsibilities
              </p>

              <ul className="list-disc text-left pl-4">
                <li className="p-2">
                  Identify prospective client companies by doing thorough web
                  research.
                </li>
                <li className="p-2">
                  Assist the sales representatives by providing new lead
                  information.
                </li>
                <li className="p-2">
                  Updating the existing lead information based on the
                  prospecting outcome in Dynamics CRM.
                </li>
              </ul>
            </div>
            <div>
              <p className="text-2xl font-bold  text-left py-3 ">
                Skills Knowledge and Expertise
              </p>

              <ul className="list-disc text-left pl-4">
                <li className="p-2">
                  Identify prospective client companies by doing thorough web
                  research.
                </li>
                <li className="p-2">
                  Assist the sales representatives by providing new lead
                  information.
                </li>
                <li className="p-2">
                  Updating the existing lead information based on the
                  prospecting outcome in Dynamics CRM.
                </li>
              </ul>
            </div>
            <div className="pt-5">
              <p id="apply" className="text-3xl lg:text-5xl font-bold  text-left py-10 ">
                How to Apply
              </p>
              <p className="pb-10 lg:pb-20 text-left text-xl">
                {" "}
                If your are interested to apply for this post please Email at{" "}
                <span className="text-black font-bold">jobs@stacksages.com</span> with
                the subject as Job tittle with your latest resume
              </p>
            </div>
          </div>

          <div className="col-span-1  lg:col-span-1  mx-10  pb-10">
            <div className=" w-max  container  mx-auto sticky    top-28 ">
              <div className="card  w-72 lg:w-64 bg-neutral-200 p-7 ">
                <div className=" ">


                  <div className="pt-4">
                    <div className="py-2 ">
                      <p className="text-left font-bold text-lg">
                        Application Deadline:
                      </p>
                      <p className="text-left">01</p>
                    </div>
                    <div className="py-2">
                      <p className="text-left font-bold text-lg">
                        Employment Type:
                      </p>
                      <p className="text-left">01</p>
                    </div>
                    <div className="py-2">
                      <p className="text-left font-bold text-lg">Location:</p>
                      <p className="text-left">01</p>
                    </div>
                    <div className="py-2">
                      <p className="text-left font-bold text-lg">
                        Workplace type:
                      </p>
                      <p className="text-left">01</p>
                    </div>
                    <div className="py-2">
                      <p className="text-left font-bold text-lg">Salary: </p>
                      <p className="text-left">01</p>
                    </div>
                  </div>
                </div>
                <div className="pt-5">
                    <a href="#apply" className="  btn btn-primary">
                      Apply
                    </a>
                  </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CareerDetails;
