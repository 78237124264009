import axios from "axios";
import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import { NavLink } from "react-router-dom";
import { blogApi } from "../../config";
import Collaboration from "../../Components/Collaboration/Collaboration";
import FooterAlt from "../../Components/FooterAlt/FooterAlt";
import BlogCard from "../../Components/BlogCard/BlogCard";
import useTitle from "../../Hooks/useTitle";
import { useGetAllBlogsQuery } from "../../redux/blogSlice";

const Blog = () => {
  useTitle("Blog");
  // const arr = [1, 2, 3];
  const [blogs, setBlogs] = useState([]);
  const [axiosblogs, setAxiosBlogs] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  const [page, setPage] = useState(1);
  const [data, setData] = useState([]);
  const { data : blogData  } = useGetAllBlogsQuery({ page: 1 });

  console.log("fromRedux", blogData?.data[0]);

  const fetchData = async () => {
    setIsLoading(true);
    setError(null);

    try {
      const response = await fetch(blogApi + `?per_page=6&page=${page}`);



      const resData = await response.json();
      setData(resData);
      setBlogs((prevItems) => [...prevItems, ...resData.data]);
      setPage((prevPage) => prevPage + 1);
      setIsLoading(false);
    } catch (error) {
      setError(error);
    } finally {
    }
  };

  // console.log(blogs)
  useEffect(() => {
    fetchData();
  }, []);

  const handleScroll = () => {
    if (
      window.innerHeight + document.documentElement.scrollTop !==
        document.documentElement.offsetHeight ||
      isLoading
    ) {
      // console.log("page end");
      return;
    }
    if(data.to){
      console.log("page end");
      fetchData();
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, [isLoading]);

  console.log(blogs);
  return (
    <div className="">
      <h1 className="pt-32 pb-6 text-4xl font-semibold lg:text-6xl">
        Our Blogs
      </h1>
      <BlogCard blogs={blogs}></BlogCard>
      {data?.to === null ? (
        <div>
          <Collaboration></Collaboration>
          <FooterAlt></FooterAlt>
        </div>
      ) : (
        <div
          className="inline-block mb-10 h-8 w-8 animate-spin rounded-full border-4 border-solid border-current border-r-transparent align-[-0.125em] motion-reduce:animate-[spin_1.5s_linear_infinite]"
          role="status"
        >
          <span className="!absolute !-m-px !h-px !w-px !overflow-hidden !whitespace-nowrap !border-0 !p-0 ![clip:rect(0,0,0,0)]">
            Loading...
          </span>
        </div>
      )}
    </div>
  );
};

export default Blog;
