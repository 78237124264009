import React, { useRef, useState } from "react";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import "react-tabs/style/react-tabs.css";
import { sendQuery, sendProposal } from "../../config";
import Button from "../../Components/Button/Button";
import useTitle from "../../Hooks/useTitle";

const Contact = () => {
  useTitle('Contact')
  const [budget, setBudget] = useState(500);
  // const [web, setWeb] = useState(false)
  // const [mobile, setMobile] = useState(false)
  // const [others, setOthers] = useState(false)

  const [projects, setProjects] = useState([]);
  const successMsgRef = useRef();
  const activeRef1 = useRef();
  const activeRef2 = useRef();

  function handleProjectCheckBox(e) {
    const { value, checked } = e.target;

    if (checked) {
      setProjects((pre) => [...pre, value]);
    } else {
      setProjects((pre) => {
        return [...pre.filter((project) => project !== value)];
      });
    }
  }
  // console.log("type", projects);

  const getQuote = (e) => {
    e.preventDefault();
    let email = e.target.email.value;
    const name = e.target.name.value;
    const reference_site = e.target.reference_site.value;
    const message = e.target.message.value;
    const form1 = {
      name: name,
      email: email,
      reference_site: reference_site,
      message: message,
      projects,
      budget,
    };

    console.log(form1);

    fetch(sendProposal, {
      method: "POST",
      headers: {
        accept: "application/json",
        "content-type": "application/json",
      },
      body: JSON.stringify(form1),
    })
      .then((res) => res.json())
      .then((data) => {
        console.log(data);
        // successMsgRef.current.classList.add( 'fade-in')
        successMsgRef.current.classList.remove("hidden");
        successMsgRef.current.classList.add("fade-out");
        setTimeout(() => {
          successMsgRef.current.classList.add("hidden");
        }, 5500);
        // const successMsgdiv = document.getElementById("success");
        //  console.log("ssss", successMsgdiv);
        // successMsgdiv.classList.remove("hidden")
      })
      .catch((err) => console.error(err));

    e.target.email.value = "";
    e.target.name.value = "";
    e.target.reference_site.value = "";
    e.target.message.value = "";
  };
  const contact = (e) => {
    e.preventDefault();
    let email = e.target.email.value;
    const name = e.target.name.value;
    const company_name = e.target.company_name.value;
    const contact_number = e.target.contact_number.value;
    const message = e.target.message.value;
    const form2 = {
      name: name,
      email: email,
      company_name: company_name,
      contact_number: contact_number,
      message: message,
    };

    console.log(form2);
    e.target.email.value = "";
    e.target.name.value = "";
    e.target.company_name.value = "";
    e.target.contact_number.value = "";
    e.target.message.value = "";

    fetch(sendQuery, {
      method: "POST",
      headers: {
        accept: "application/json",
        "content-type": "application/json",
      },
      body: JSON.stringify(form2),
    })
      .then((res) => res.json())
      .then((data) => {
        console.log(data);
        successMsgRef.current.classList.remove("hidden");
        successMsgRef.current.classList.add("fade-out");
        setTimeout(() => {
          successMsgRef.current.classList.add("hidden");
        }, 5500);
      })
      .catch((err) => console.error(err));
  };

  const [toggle, setToggle] = useState(1);

  function updateToggle(id) {
    setToggle(id);
    if (id === 2) {
      activeRef1.current.classList.remove("tab-active");
      activeRef2.current.classList.add("tab-active");
    } else {
      activeRef2.current.classList.remove("tab-active");
      activeRef1.current.classList.add("tab-active");
    }
  }
  // console.log(budget)

  // console.log(typeOfProject)

  return (
    <div className="">
      <h1 className="pt-32  w-3/5 mx-auto text-4xl font-semibold px-10 lg:text-6xl">
        Contact
      </h1>
      <section></section>
      <div className=" w-6/12 flex flex-col justify-center items-center mx-auto pb-20 pt-16">
        <div className="tabs flex flex-col md:flex-row  justify-center items-center ">
          <div 
            onClick={() => updateToggle(1)}
            ref={activeRef1}
            className="tab tab-lg tab-bordered tab-active text-base lg:text-2xl font-semibold "
          >
            Send Your Proposal
          </div>
          <div 
            onClick={() => updateToggle(2)}
            ref={activeRef2}
            className="  tab tab-lg tab-bordered text-base lg:text-2xl font-semibold "
          >
            Send Your Message
          </div>
        </div>

        {/* <TabList>
            <Tab>Send Your Proposal</Tab>
            <Tab>Send Your Query</Tab>
          </TabList> */}
        <div className={toggle === 1 ? "block  " : "hidden"}>
          <h2>
            <div className="pt-10">
              <div className="  card flex-col lg:flex-row-reverse ">
                <div className="shadow-lg  rounded-xl  ">
                  <form
                    onSubmit={getQuote}
                    className=" card-body  bg-white  rounded-xl "
                  >
                    <div className="flex flex-col lg:flex-row justify-between gap-5">
                      <div className="form-control">
                        <label className="label">
                          <span className="label-text">
                            Name <span className="text-red-500">*</span>
                          </span>
                        </label>
                        <input
                          type="text"
                          placeholder="Name"
                          name="name"
                          className="input input-bordered bg-white"
                        />
                      </div>
                      <div className="form-control">
                        <label className="label">
                          <span className="label-text">
                            Email <span className="text-red-500">*</span>{" "}
                          </span>
                        </label>
                        <input
                          type="email"
                          name="email"
                          placeholder="Email"
                          className="input input-bordered bg-white"
                        />
                      </div>
                    </div>
                    <div className="">
                      <div className="form-control">
                        <label className="label">
                          <span className="label-text">Reference Site</span>
                        </label>
                        <input
                          type="text"
                          name="reference_site"
                          placeholder="Reference Site"
                          className="input input-bordered bg-white"
                        />
                      </div>
                    </div>
                    <div className="form-control">
                      <label className="label">
                        <span className="label-text">Your Message</span>
                      </label>
                      <textarea
                        type="textarea"
                        name="message"
                        placeholder="Your Message"
                        className="input input-bordered textarea-lg h-24 bg-white"
                      />
                    </div>
                    <div className="form-control mt-5">
                      <label className="label">
                        <span className="label-text">
                          Your Budget <span className="text-red-500">*</span>
                        </span>
                      </label>
                      <div className="flex flex-col lg:flex lg:flex-row items-start justify-between">
                        <div className="form-control">
                          <label className="label cursor-pointer">
                            <input
                              type="radio"
                              name="radio-10"
                              className="radio"
                              value="500"
                              onClick={(e) => setBudget(500)}
                              defaultChecked
                            />
                            <span className="label-text px-2">$500</span>
                          </label>
                        </div>
                        <div className="form-control">
                          <label className="label cursor-pointer">
                            <input
                              type="radio"
                              name="radio-10"
                              className="radio"
                              value="1000"
                              onClick={(e) => setBudget(1000)}
                            />
                            <span className="label-text px-2">$1000</span>
                          </label>
                        </div>
                        <div className="form-control">
                          <label className="label cursor-pointer">
                            <input
                              type="radio"
                              name="radio-10"
                              className="radio"
                              value="1500"
                              onClick={(e) => setBudget(1500)}
                            />
                            <span className="label-text px-2">$1500</span>
                          </label>
                        </div>
                      </div>
                    </div>
                    <div className="form-control">
                      <label className="label">
                        <span className="label-text">
                          Type of Project{" "}
                          <span className="text-red-500">*</span>
                        </span>
                      </label>
                      <div className="flex flex-col lg:flex lg:flex-row items-start justify-between">
                        <div className="form-control">
                          <label className="label cursor-pointer">
                            <input
                              type="checkbox"
                              name="web-app"
                              className="checkbox"
                              value="web-app"
                              // onClick={(e) => {

                              //   setWeb(!web)

                              // }
                              // }
                              onChange={handleProjectCheckBox}
                            />
                            <span className="label-text px-2">Web Site</span>
                          </label>
                        </div>
                        <div className="form-control">
                          <label className="label cursor-pointer">
                            <input
                              type="checkbox"
                              name="mobile-app"
                              className="checkbox"
                              value="mobile-app"
                              // onClick={(e) => {
                              //   setMobile(!mobile)

                              // }}
                              onChange={handleProjectCheckBox}
                            />
                            <span className="label-text px-2">Mobile App </span>
                          </label>
                        </div>
                        <div className="form-control">
                          <label className="label cursor-pointer">
                            <input
                              type="checkbox"
                              name="others"
                              className="checkbox"
                              value="others"
                              //   onChange={(e) => {
                              //     setOthers(!others)

                              //   }
                              // }
                              onChange={handleProjectCheckBox}
                            />
                            <span className="label-text px-2">Others</span>
                          </label>
                        </div>
                      </div>
                    </div>

                    <div className="form-control mt-6">
                      <div className=" lg:flex justify-center cursor-pointer items-center gap-5 mt-7">
                        <Button

                          text="Submit"
                        />
                      </div>
                    </div>

                    <div
                      ref={successMsgRef}
                      id="success"
                      className="hidden my-1  font-bold text-lg"
                    >
                      {" "}
                      We received your message successfully.<br></br>
                      We will get back to you as soon as possible.
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </h2>
        </div>

        <div className={toggle === 2 ? "block  " : "hidden"}>
          <h2>
            <div className="pt-10">
              <div className="  card flex-col lg:flex-row-reverse">
                <div className=" shadow-lg  rounded-xl">
                  <form
                    onSubmit={contact}
                    className=" card-body bg-white  rounded-xl "
                  >
                    <div className="flex flex-col lg:flex-row justify-between gap-5">
                      <div className="form-control">
                        <label className="label">
                          <span className="label-text">
                            Name <span className="text-red-500">*</span>
                          </span>
                        </label>
                        <input
                          type="text"
                          name="name"
                          placeholder="Name"
                          className="input input-bordered bg-white"
                          required
                        />
                      </div>
                      <div className="form-control">
                        <label className="label">
                          <span className="label-text">
                            Email <span className="text-red-500">*</span>
                          </span>
                        </label>
                        <input
                          type="email"
                          name="email"
                          placeholder="Email"
                          className="input input-bordered bg-white"
                        />
                      </div>
                    </div>
                    <div className="flex flex flex-col lg:flex-row justify-between ">
                      <div className="form-control">
                        <label className="label">
                          <span className="label-text">Company Name</span>
                        </label>
                        <input
                          type="text"
                          name="company_name"
                          placeholder="Company Name"
                          className="input input-bordered bg-white"
                        />
                      </div>
                      <div className="form-control">
                        <label className="label">
                          <span className="label-text">
                            Contact Number{" "}
                            <span className="text-red-500">*</span>
                          </span>
                        </label>
                        <input
                          type="number"
                          name="contact_number"
                          placeholder="Contact Number"
                          className="input input-bordered bg-white"
                          required
                        />
                      </div>
                    </div>
                    <div className="form-control">
                      <label className="label">
                        <span className="label-text">Your Message</span>
                      </label>
                      <textarea
                        type="textarea"
                        placeholder="Your Message"
                        className="input input-bordered textarea-lg bg-white h-24"
                        name="message"
                      />
                    </div>
                    <div className="form-control mt-6">
                    <div className=" lg:flex justify-center cursor-pointer items-center gap-5 mt-7">
                        <Button

                          text="Submit"
                        />
                      </div>
                    </div>
                    <div
                      ref={successMsgRef}
                      id="success"
                      className=" hidden my-1 font-bold text-lg"
                    >
                      {" "}
                      We received your message successfully.<br></br>
                      We will get back to you as soon as possible.
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </h2>
        </div>
      </div>
    </div>
  );
};

export default Contact;
