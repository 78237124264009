import React from 'react';
import { Link } from 'react-router-dom';
import Button from '../Button/Button';

const Collaboration = () => {
    return (
        <div>
                  <section>
      <div className="hero pb-5 lg:pb-12 lg:pr-10 lg:pl-10 lg:p-0  bg-white">
        <div className="hero-content flex-col-reverse mt-10 pt-10 pb-10 lg:flex-row-reverse">
          
          <div className="w=2/3 lg:w-1/3 flex-row">
            <h1 className=" text-2xl  font-bold lg:text-5xl lg:text-left pt-14 ">
            Interested in
Collaboration?
            </h1>
            <p className="py-6 mx-5 lg:mx-0 lg:text-left  lg:flex  ">
            Challenge us.
We want to work with you to create the really cool stuff.
            </p>
            <div className=" flex justify-center lg:justify-start items-center gap-5 mt-7">
              <Link to="/contact">
                <Button
                  img={
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      strokeWidth={2}
                      stroke="currentColor"
                      className="w-6 h-6"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="M13.5 4.5L21 12m0 0l-7.5 7.5M21 12H3"
                      />
                    </svg>
                  }
                  text="Contact"
                />
              </Link>
            </div>
          </div>
          <img src="collaboration.jpg" className=" md:w-2/3 lg:w-1/2 rounded-lg " />
        </div>
      </div>
      </section>
        </div>
    );
};

export default Collaboration;