import React from "react";
import { Link } from "react-router-dom";

const BlogCard = ({ blogs }) => {
  return (
    <div>
      <div className="grid grid-cols-1 gap-10 pt-10 pb-24 mx-auto  lg:w-11/12 md:grid-cols-2 lg:grid-cols-3 px-7">
        {blogs?.map((blog, index) => (
          <Link
            key={index}
            to={`/blog-details/${blog.slug}`}
            className="bg-white shadow-xl  card"
          >
            <figure>
              <img className="h-48 w-96" src={blog.image} alt="Shoes" />
            </figure>
            <div className="-mb-5 card-body">
              {/* <div className="badge badge-secondary">Laravel</div> */}
              <div className="flex justify-start text-lg font-semibold ">
                <p className="text-left">
                  {blog.title.length < 600
                    ? blog.title.slice(0, 40)
                    : blog.title}{" "}
                  {blog.id}
                </p>
              </div>
              <p
                dangerouslySetInnerHTML={{
                  __html:
                    blog.description.length > 60
                      ? blog.description.slice(0, 70)
                      : blog.description,
                }}
                className="text-left"
              >
                {}
              </p>
            </div>
            <div className="flex justify-between mx-8 my-5">
              <div className="badge badge-lg badge-primary badge-outline">
                {" "}
                {blog?.category?.[0]?.name}
              </div>
              <div className="badge badge-lg badge-primary badge-outline">
                {blog.reading_time} Min Read
              </div>
            </div>
          </Link>
        ))}
      </div>
    </div>
  );
};

export default BlogCard;
