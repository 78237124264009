import React from "react";
import { NavLink } from "react-router-dom";
import useTitle from "../../Hooks/useTitle";
const Career = () => {
  useTitle('Career')
  let Career_array = [1, 2, 3, 4, 5];
  return (
    <div className=" relative pb-20">
        <h1 className="pt-32 lg:pb-14  text-4xl font-semibold lg:text-6xl">
          Career
        </h1>
        
      <p className="text-3xl mx-10 font-semibold text-center"> There is no vacancy right now.  </p>
      <p className="pt-5 text-xl  mx-10 text-center"> You can send your Resume at <span className="font-bold"> </span> <a  href="mailto:career@stacksages.com" className="text-green-600 font-bold">career@stacksages.com</a>  </p>
      {/* {Career_array.map((Career, index) => (
        <div className="container  mx-auto py-5 " key={index}>
          <div className=" mx-5 group flex-row lg:flex items-center border-2 rounded-xl border-neutral-200 bg-neutral-200  shadow-2xl group-hover:border hover:border-green-600">
            <div className="card-body">
              <h2 className="card-title py-4 ">JavaScript Developer</h2>

              <div className="text-left grid grid-cols-2 gap-3">
                <p>
                  <span className="font-bold">Vacancy: </span> 01
                </p>
                <p>
                  <span className="font-bold">Salary: </span> Negotiable
                </p>
              </div>
              <div className="text-left grid grid-cols-2 gap-3">
                <p>
                  <span className="font-bold">Job Location: </span> Dhaka
                </p>
                <p>
                  <span className="font-bold">Experience: </span> 0-2 years
                </p>
              </div>
              <div className="flex gap-4 pt-3">
                <div className="badge badge-lg badge-outline p-3.5 ">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    strokeWidth="1.5"
                    stroke="currentColor"
                    className="w-6 h-6 pr-1"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M12 6v6h4.5m4.5 0a9 9 0 11-18 0 9 9 0 0118 0z"
                    />
                  </svg>
                  Full Time
                </div>
                <div className="badge badge-lg p-3.5">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    strokeWidth={1.5}
                    stroke="currentColor"
                    className="w-6 h-6 pr-1"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M20.25 14.15v4.25c0 1.094-.787 2.036-1.872 2.18-2.087.277-4.216.42-6.378.42s-4.291-.143-6.378-.42c-1.085-.144-1.872-1.086-1.872-2.18v-4.25m16.5 0a2.18 2.18 0 00.75-1.661V8.706c0-1.081-.768-2.015-1.837-2.175a48.114 48.114 0 00-3.413-.387m4.5 8.006c-.194.165-.42.295-.673.38A23.978 23.978 0 0112 15.75c-2.648 0-5.195-.429-7.577-1.22a2.016 2.016 0 01-.673-.38m0 0A2.18 2.18 0 013 12.489V8.706c0-1.081.768-2.015 1.837-2.175a48.111 48.111 0 013.413-.387m7.5 0V5.25A2.25 2.25 0 0013.5 3h-3a2.25 2.25 0 00-2.25 2.25v.894m7.5 0a48.667 48.667 0 00-7.5 0M12 12.75h.008v.008H12v-.008z"
                    />
                  </svg>
                  Remote
                </div>
              </div>
            </div>
            <div className=" mb-10 lg:mr-10 lg:mb-0">
              <NavLink to='/career/details' className="btn btn-outline border-3 group-hover:border-primary group-hover:bg-primary group-hover:text-white">
                View Details
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  strokeWidth="1.5"
                  stroke="currentColor"
                  className="w-6 h-6 pl-1"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M17.25 8.25L21 12m0 0l-3.75 3.75M21 12H3"
                  />
                </svg>
              </NavLink>
            </div>
          </div>
        </div>
      ))} */}

      
    </div>
  );
};

export default Career;
